import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryFilter, CategoryItem } from '../../../shared/_models/category-filter.model';
import { CategoryPluralTitlePipe } from '../../../shared/_pipes/category-plural-title.pipe';

@Component({
  selector: 'fpc-category-button',
  templateUrl: './category-button.component.html',
  styleUrls: ['./category-button.component.scss']
})
export class CategoryButtonComponent implements OnInit {

  _selectedCategoryItemIds: string[] = [];

  isCollapsed: boolean = true;

  _filter;

  @Input() set filter(value) {
    this._filter = value;
    if (value) {

      this._filter.items.forEach(element => {
        if (element.isSelected) {
          this._selectedCategoryItemIds.push(element.id);
        }
      })

    }
  }

  get filter() {
    return this._filter;
  }

  @Input() isInternalPage: boolean = false;

  @Input() set selectedCategories(value: string[]) {
    this._selectedCategoryItemIds = value;
  }

  get selectedCategories(): string[] {
    return this._selectedCategoryItemIds;
  }

  @Input() isFilterType: boolean = false;
  @Input() screenWidth: number;
  @Input() activeButton: string;
  @Input() text: string;
  @Input() badgeText: string;
  @Output() getCategorySelectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private pluralPipe: CategoryPluralTitlePipe) { }

  ngOnInit() {

    if (!this._selectedCategoryItemIds) {
      this._selectedCategoryItemIds = [];
    }
    if (this._selectedCategoryItemIds.length > 0) {

      this._selectedCategoryItemIds.forEach((value) => {
        this.filter.items = this.filter.items.map((item) => {
          if (item.id == value) item.isSelected = true;
          return item;
        });
      });
    };

    this.filter.items.forEach((item) => {
      if (item.isSelected) {
        this.selectedCategories.push(item.id);
      }
    });
  }


  onCatClick(btnId: string, item: CategoryItem) {

    this._filter.items.forEach((item) => {
      item.isSelected = false;
    });

    let index: number = this._selectedCategoryItemIds.indexOf(btnId);

    if (this.filter._filterTitle === 'Filters') {
      if (index !== -1) {
        this._selectedCategoryItemIds.splice(index, 1);
        item.isSelected = false;
      } else {
        this._selectedCategoryItemIds.push(btnId);
        item.isSelected = true;
      }
    }
    else {
      this._selectedCategoryItemIds = [btnId];
    }

    item.isSelected = true;

    this.categorySelectionChanged();
  }
  categorySelectionChanged() {
    this.getCategorySelectionChanged.emit(this.selectedCategories);
  }

  getActiveClass(itemId) {
    return this._selectedCategoryItemIds.includes(itemId) ? true : false;
  }

  getTitle(item) {
    return item.isSelected ? this.pluralPipe.transform(item.name) : "Scroll to " + this.pluralPipe.transform(item.name).toLowerCase();
  }
  scrollTo(section) {
    if (this.selectedCategories.length === 1) {
      this._selectedCategoryItemIds = [];
      this.getCategorySelectionChanged.emit(this._selectedCategoryItemIds);
    }
    else {
      let el: any = document.getElementById(section);
      window.scrollTo({ top: el.offsetTop - 110, behavior: 'smooth' });
    }
  }
}