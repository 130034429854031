import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../../buttons/button/button.module';
import { InputDatePickerModule } from '../input-date-picker/input-date-picker.module';
import { InputSelectComponent } from './input-select.component';

@NgModule({
  declarations: [InputSelectComponent],
  imports: [CommonModule, NgbModule, FormsModule, ButtonModule, InputDatePickerModule],
  exports: [InputSelectComponent],
})
export class InputSelectModule {}
