<!-- [ngClass]="{'ctrl-headerGlobalSearch': search == true,'ctrl-header': search == false}" -->
<div *ngIf="model && showHeader" class="header" (widgetHovered)="onWidgetHover()" (widgetLeft)="onWidgetLeave()" [ngClass]="search? 'global-search': ''">
  <div class="title">
    <!--default widget title-->
    <h4 class="m-0 text-nowrap" *ngIf="!searchSection">
      <i class="fal" [ngClass]="[(inactive ? 'text-gray' : 'text-primary'), (model.headerIcon ? model.headerIcon : icon || '')]"></i>
      <span *ngIf="title" class="mx-3 fw-bold" [title]="title">{{title}}</span>
      <span *ngIf="inactive" class="inactive-text fw-bold text-gray-dark text-truncate">{{model.inactiveText}}</span>
    </h4>
    <!--default widget title-->

    <!--global search widget title-->
    <!-- [ngClass]="{'h2GlobalSearch': search == true}" -->
    <h2 *ngIf="searchSection" class="fw-bold">
      <ng-container *ngIf="isOneCatSelected">
        <span *ngIf="searchSection.title.indexOf('Associated') > -1">{{searchSection.title}}</span>
        <span *ngIf="searchSection.title.indexOf('Associated') == -1">Search results</span>
        <span class="mr-4 turquoise" *ngIf="!isMapView || searchSection.title!='Project'">{{searchSection.curRecordsOnPage}}/{{searchSection.totalRecords}}</span>
        <span class="mr-4 turquoise" *ngIf="isMapView && searchSection.title=='Project'">{{searchSection.markerData.length}}/{{searchSection.totalRecords}}</span>
      </ng-container>

      <div *ngIf="!isOneCatSelected">
        <span class="turquoise ml-1">{{model.curRecordsOnPage}}/{{model.totalRecords}}</span>
      </div>

    </h2>

    <div *ngIf="(showTableView &&!isMapView) && showLoadMoreBtn" class="button-group d-flex justify-content-end">
      <!-- Icon -->
      <div class="save_icon" [ngClass]="[complete ? 'saved' : '', boolean ? '' : 'd-none']">
        <fpc-button [buttonLayoutType]="'icon'" [iconClass]="'fal fa-check'"></fpc-button>
      </div>
      <!-- X Icon -->

      <!-- Button -->
      <button type="button" class="btn btn-outline-primary" (click)="btnClick()" [ngClass]="[boolean ? 'active' : '' ]">
        <span class="title">{{!complete ? (boolean ? 'Loading' : 'Load all results') : 'Loaded more results'}}</span>
      </button>
      <!-- X Button -->
    </div>
  </div>

  <!-- [ngClass]="{'ctrl-content': search == false}" ctrls class -->
  <div class="controls" *ngIf="(model.showControls || !model.isHidden) && !inactive">
    <!--global search widget actions-->

    <ng-container *ngIf="searchSection">
      <ng-container *ngIf="!isOneCatSelected">

        <div *ngIf="showSectionExpandBtn" class="d-flex justify-content-center">
          <fpc-button title="'Card view'" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-primary'" [iconClass]="'fal fa-th'"
            (buttonClick)="saveToggleState(searchSection.title, true)">
          </fpc-button>
          <fpc-button title="'Table view'" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-primary'" [iconClass]="'fal fa-th-list'"
            (buttonClick)="saveToggleState(searchSection.title, false)">
          </fpc-button>
        </div>

      </ng-container>

      <ng-container *ngIf="isOneCatSelected">
        <!-- [customNgStyle]="{'width':'40px', 'height':'40px', 'margin-right': '1.2rem'}" [iconStyle]="{'font-size':'1.9rem'}"-->
        <fpc-button *ngIf="searchSection.title!='Project' || !isMapView" [buttonLayoutType]="'icon'" [active]="this.showPreset ? true: false"
          [buttonColorClass]="'outline-primary'" [iconClass]="'fal fa-tasks'" (buttonClick)="setPreset()" [title]="'Preset'">
        </fpc-button>

        <div class="vr"></div>

        <fpc-button *ngIf="searchSection.title=='Project'" [buttonLayoutType]="'icon'" [iconClass]="'fal fa-map'" (buttonClick)="saveToggleStateMapView(true)"
          [title]="'Map view'">
        </fpc-button>

        <fpc-button [title]="'Card view'" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-primary'" [iconClass]="'fal fa-th'"
          (buttonClick)="changeToggle(searchSection.title, false, false);">
        </fpc-button>

        <fpc-button [title]="'Table view'" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-primary'" [iconClass]="'fal fa-th-list'"
          (buttonClick)="changeToggle(searchSection.title, true, false);">
        </fpc-button>

        <fpc-button *ngIf="showOnlyProjectsWithImagesToggle && !isMapView" [title]="'Projects with images only'" [buttonLayoutType]="'icon'" [iconClass]="'fal fa-eye'"
          (buttonClick)="showOnlyWithImage()"></fpc-button>

      </ng-container>
    </ng-container>
    <!--global search widget actions-->

    <!--default widget actions-->
    <ng-container *ngIf="!searchSection">
      <fpc-button *ngIf="model.type == 'Calendar'" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'" [iconClass]="'fal fa-undo'"
        (buttonClick)="onResetBtnClick()" title="Today's date"></fpc-button>

      <fpc-button *ngIf="model.showEditIcon && !model.isMinimised" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'" [iconClass]="'fal fa-pen'"
        (buttonClick)="onEditIconClick($event)" [title]="'Edit content'" [active]="isEditActive"></fpc-button>

      <fpc-button *ngIf="model.showPrevBtn" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'" (buttonClick)="onBackBtnClick($event)"
        [disabled]="isCarouselPrevBtnDisabled || showTableView" [iconClass]="'fal fa-arrow-left'" [title]="'Previous'"></fpc-button>

      <fpc-button *ngIf="model.showNextBtn" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'" (buttonClick)="onNextBtnClick($event)"
        [disabled]="isCarouselNextBtnDisabled || showTableView" [iconClass]="'fal fa-arrow-right'" [title]="'Next'"></fpc-button>

      <fpc-button *ngIf="model.showTableViewToggle && !onSidebar" [title]="showTableView ? 'Table view' : showDoubleCardView ? 'Card view multi row' : 'Card view'"
        [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'"
        [iconClass]="showCardView ? 'fal fa-th-large' : showDoubleCardView ? 'fal fa-th' : 'fal fa-th-list' " (buttonClick)="toggleViewState()">
      </fpc-button>

      <fpc-button *ngIf="searchInWidget" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'" [iconClass]="'fal fa-search'"
        (buttonClick)="onSearchInWidgetBtnClick()" [title]="'Search in ' + title.toLowerCase()"></fpc-button>

      <fpc-button *ngIf="model.showMinimizeBtn  && !showExclamation" [title]="model.isMinimised?'Expand widget':'Minimize widget'" [buttonLayoutType]="'icon'"
        [buttonColorClass]="'outline-transparent'" [iconClass]="model.isMinimised?'fal fa-expand':'fal fa-minus'" (buttonClick)="onMinimiseBtnClick(model.isMinimised)">
      </fpc-button>

      <fpc-button *ngIf="model.showExternalLinkBtn && ( model.externalLinkUrl || model.itemLinkUrl)" [buttonLayoutType]="'icon'"
        [buttonColorClass]="'outline-transparent'" [link]="model.externalLinkUrl || model.itemLinkUrl" [iconClass]="'fal fa-sign-out'"
        [title]="externalUrlTitle ? externalUrlTitle : model.externalLinkUrl || model.itemLinkUrl"></fpc-button>

      <fpc-button *ngIf="model.showGripIcon && !mainCommunity" [buttonLayoutType]="'icon'" [buttonClass]="'grip'" [buttonColorClass]="'transparent'"
        [iconClass]="'fa-kit fa-grip'"></fpc-button>

      <fpc-button *ngIf="model.close" [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-transparent'" [iconClass]="'fal fa-times'"
        (buttonClick)="onCloseBtnClick()"></fpc-button>

    </ng-container>
    <!--default widget actions-->
  </div>
</div>
<ng-container *ngIf="!model?.isMinimised">
  <ng-template [ngTemplateOutletContext]='{data: model}' [ngTemplateOutlet]="templateVariable"></ng-template>
</ng-container>