import {
  Component,
  forwardRef,
  Injector,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    }
  ],
})
export class InputTextComponent extends BaseInputComponent {

  @Input() minlength!: string;
  @Input() maxlength!: string;
  // @Input() inputHeight: 'md' | 'sm' | undefined;
  @Input() labelColor!: string;
  @Input() iconSize: string = 'md'
  @Input() inputSize!: string;
  @Input() inputClass: string = 'h4';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInitForChildren(): void { }
}
