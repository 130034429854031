import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { NgbDate, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fpc-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent implements OnInit, AfterViewInit {
  searchText: string | undefined;
  
  _filters = new Map<string, any>();
  _filterCopy = new Map<string, any>();

  
  _selectedFiltersMap: Map<string, any> = new Map<string, any>();  
  noOfFilterSelected!: number; 
 

  selectedDateDefault: { fromDate: NgbDate, toDate: NgbDate } = {
    fromDate: new NgbDate(
      new Date().getFullYear() - 1,
      new Date().getMonth() + 1,
      new Date().getDate()),
    toDate: new NgbDate(
      new Date().getFullYear(),
      (new Date().getMonth() + 1),
      new Date().getDate()),
  };
  selectedDate: { fromDate: NgbDate, toDate: NgbDate } = this.selectedDateDefault;

  tabMenuClass: string = 'search-active';
 
  @Input() filter: any;
  @Input() _selectedFiltersMapInital!: Map<string, any>;  
  @Input() showDatePicker: boolean = false;

  @Input() hideSelectDeselectAll: boolean = false;

  @Output() getFilterSelectionChanged = new EventEmitter<any>();
   
  @ViewChildren('ddb') ngbDropdownButtons!: QueryList<ElementRef>
  @ViewChild('myInput',{static:true}) myInput: any;

  @Input() set selectedFilters(value: any) {
    if (value.length > 0) {
      
      this.noOfFilterSelected = value.length;
      
      if (!this._selectedFiltersMap || !this._selectedFiltersMapInital) {
        this._selectedFiltersMap = new Map<string, any>();
        this._selectedFiltersMapInital = new Map<string, any>();
        value.forEach((x: any) => {
          this._selectedFiltersMap?.set(x.id, { "id": x.id, title: x.title, "filterItems": x.filterItems });
          var y = JSON.parse(JSON.stringify(x));
          this._selectedFiltersMapInital.set(y.id, { "id": y.id, title: y.title, "filterItems": y.filterItems });
        });
      }     

    } else {
        this.noOfFilterSelected = 0;
        this._selectedFiltersMap.clear();
        this._selectedFiltersMapInital.clear();

        this.filterSelectionChanged();
      }
  }

  get selectedFilters(): any {
    return JSON.stringify(Array.from(this._selectedFiltersMap.values()));
  }

  constructor(config: NgbDropdownConfig) {
    config.autoClose = 'outside';
  }
  ngAfterViewInit(): void {
    // this.myInput = this.myInput?.nativeElement;
  }

  ngOnInit() {   
    this.filter.items.forEach((filterItem: any) => {
      this._filters.set(filterItem.id, filterItem);
      this._filterCopy.set(filterItem.id, JSON.parse(JSON.stringify(filterItem)));
    });
  }

  /**
   * is called when the filter is selcted from dropdown
   * @param event 
   * @param filterId 
   * @param fltTitle 
   * @param valueSelected 
   */
  onFilterSelected(event: any, filterId: any, fltTitle: any, selectedDate: any, valueSelected: any) {
    if(event.type =='click') event.stopPropagation();
    let item = this._selectedFiltersMap.get(filterId);
    if (item) {
      // if item exists that means the item was already selected
      let index: number = item.filterItems.indexOf(valueSelected);
      if (index !== -1) {
        // index was found so remove the item from selected array
        item.filterItems.splice(index, 1);
        if (item.filterItems.length == 0)
          this._selectedFiltersMap?.delete(filterId);
      } else {
        item.filterItems.push(valueSelected);
      }
      item.selectedDate = selectedDate;
    } else {
      // item was not present, so add it.
      let filterItems: any[] = [];
      filterItems.push(valueSelected);
      this._selectedFiltersMap?.set(filterId, { "id": filterId, "title": fltTitle, "filterItems": filterItems });
    }
  }

  applyFilterSelection(event: any, filterId: any) {
    event.preventDefault();

    // recalculate the number of filters again
    this.noOfFilterSelected = 0;
    this._selectedFiltersMap?.forEach((value: any, key: string) => {
      this.noOfFilterSelected = this.noOfFilterSelected + value.filterItems.length;
    });

    this.sortFilters(filterId);
    this.filterSelectionChanged();
  }

  

  isApplyButtonActive(filterId: string) {
    let filter1 = this._selectedFiltersMap?.get(filterId);
    let filter2 = this._selectedFiltersMapInital?.get(filterId);
    return this.compareFilterItems(
      filter1 && filter1.filterItems ? filter1.filterItems : [],
      filter2 && filter2.filterItems ? filter2.filterItems : []
    );
  }

  compareFilterItems(fitems1: any, fitems2: any): boolean {
    if (fitems1.length == 0 && fitems2.length == 0) return false;
    else return JSON.stringify(fitems1.sort()) != JSON.stringify(fitems2.sort());
  }

  sortFilters(filterId: any) {
    this._filters.get(filterId).values.sort((a: any, b: any) => {
      return this.isFilterSelected(filterId, a) ? (this.isFilterSelected(filterId, b) ? 1 : -1) : 0
    });
  }

  /**
   * 
   * @param filterId 
   * @param valueSelected 
   */
  isFilterSelected(filterId: any, valueSelected: any) {
    let item = this._selectedFiltersMap?.get(filterId);

    return item && item.filterItems ? item.filterItems.includes(valueSelected) : false;
  }

  asIsOrder(a: any, b: any) {
    const aStepId = parseInt(a.value.order, 10);
    const bStepId = parseInt(b.value.order, 10);
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }

  /**
   * 
   * @param event 
   * @param filterId 
   */
  onSelectAll(event: any, filter: any, apply?: boolean) {
    event.stopPropagation();
    let filterItems = [...filter.values];
    this._selectedFiltersMap?.set(filter.id, { "id": filter.id, "title": filter.title, "filterItems": filterItems, "badge": filter.badge });
    if (apply) this.applyFilterSelection(event, filter.id);
    
  }

  /**
   * remove the items from selected list
   * @param event 
   * @param filterId id of the filter on which the opeartion is being performed
   */
  onDeSelectAll(event: any, filter: any, apply?: boolean) {
    event.stopPropagation();
    this._selectedFiltersMap?.delete(filter.id);
    if (apply) this.applyFilterSelection(event, filter.id);
  }


  /**
   * 
   * @param event 
   * @param filterId 
   * @param value 
   */
  handleInput(event: any, filterId: any, value: any) {

    let internalFilterArr = this._filters?.get(filterId);
    let selectedFilter = this._filterCopy?.get(filterId);
    if (value) {
      internalFilterArr.values = selectedFilter.values.filter((item: any) => item.toLowerCase().includes(value.toLowerCase()));
    } else {
      internalFilterArr.values = selectedFilter.values;
    }
    this.sortFilters(filterId);
  }



  filterSelectionChanged() {
    this._selectedFiltersMapInital = new Map(this._selectedFiltersMap);
    this.getFilterSelectionChanged.emit(JSON.stringify(Array.from(this._selectedFiltersMap.values())));
  }

  resetFilterValues(filterId: any) {
    this.searchText = undefined;
    this._filters.get(filterId).values = this._filterCopy.get(filterId).values;
    this.sortFilters(filterId);
  }


  onDateSelectionChanged(valArray: any, filter: any) {
    if (!valArray.fromDate || !valArray.toDate) {
      return;
    }

    let item = this._selectedFiltersMap.get(filter.id);
    if (item) {
      item.filterItems = [];
    }

    this.onFilterSelected(valArray, filter.id, filter.title, undefined, valArray.fromDate.year + '-' + ('0' + valArray.fromDate.month).slice(-2) + '-' + ('0' + valArray.fromDate.day).slice(-2))
    //this.selectedDate = valArray.fromDate && valArray.toDate ? valArray : this.selectedDateDefault;
    this.onFilterSelected(valArray, filter.id, filter.title, undefined, valArray.toDate.year + '-' + ('0' + valArray.toDate.month).slice(-2) + '-' + ('0' + valArray.toDate.day).slice(-2))
    document.getElementById('dropdownMenuButton_' + filter.id)?.click();
    this.filterSelectionChanged();
  }
}
