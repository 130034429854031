import { Component, OnInit, Input, Injector } from '@angular/core';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-field-label',
  templateUrl: './input-field-label.component.html',
  styleUrls: ['./input-field-label.component.scss']
})
export class InputFieldLabelComponent extends BaseInputComponent implements OnInit {
  @Input() iconSize: string = 'md';
  @Input() labelColor: string = 'gray';
  @Input() required: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  setOtherProperty(value: any): void { }

  writeValue(input: any): void { }

  ngOnInitForChildren(): void { }
}
