import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fpc-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {
  @Input() id: string;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() labelText: string;

  @Output() isChecked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }
  
  isValueChecked(toggleValue){
    this.isChecked.emit(toggleValue.target.checked); 
  }
}
