import {
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true,
    }
  ],
})
export class InputRadioComponent extends BaseInputComponent implements OnInit {

  idEx = (Math.random()).toString();

  @Input() name: string; 

  @Input('options') options;

  constructor(injector: Injector) {
    super(injector);
  }


  ngOnInitForChildren(): void {}
}
