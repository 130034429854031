import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { AccordionPanel } from '../../shared/_models/accordion.model';

@Component({
  selector: 'fpc-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterViewInit {  
  
  isAccordionOpened: boolean = false;

  @Input() accordionPannels!: AccordionPanel[];
  @Input() closeOthers: boolean = null;
  @Input() iconSize: string;
  @Input() squared: boolean = false;
  @Input() accordionColorClass: string;
  
  @ViewChild('accordionTemplate') accordionTemplate: any;
  @ViewChild('childAccordionTemplate') childAccordionTemplate: any;
  @ViewChild('accordionItem') accordionItem: any;
  

  ngAfterViewInit(): void {
    this.checkIfAccordioIsOpen();   
  }

  checkCollapsed(selectedItem){
    this.isAccordionOpened = !selectedItem._collapsed ? false : true;
  }

  checkIfAccordioIsOpen(){
    this.accordionTemplate._items._results.forEach((item) => {
      this.isAccordionOpened = item._collapsed ? false : true;
    });
  }
}