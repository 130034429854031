import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../../buttons/button/button.module';
import { InputFieldLabelComponent } from './input-field-label.component';

@NgModule({
  declarations: [InputFieldLabelComponent],
  imports: [CommonModule, NgbModule, FormsModule, ButtonModule],
  exports: [InputFieldLabelComponent],
})
export class InputFieldLabelModule {}
