import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Injector,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  FormControlName,
  FormGroupDirective,
  FormControlDirective,
  Validators,
  FormControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Inject } from '@angular/core';

@Directive()
export abstract class BaseInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  control: FormControl;

  id: string = (Math.floor(Math.random() * 100) + 1).toString();

  _label: string;

  _popoverText: string;

  onDestroy$ = new Subject();

  protected _onChange: any;

  protected _onTouch: (arg?: any) => void;

  protected _disabled: boolean = false;

  @Input() set label(value: string) {
    this._label = value;
    this.id = value?.replace(/\W+/g, '_').toLowerCase() + (Math.floor(Math.random() * 100) + 1).toString();
  }

  @Input() set popoverText(value: string) {
    this._popoverText = value;
  }

  @Input() formControl: FormControl = new FormControl();

  @Input() formControlName: string;

  @Input() placeholder: string | undefined;

  @Input('disabled') public set disabled(value: boolean) {
    if (this._disabled === value) return;

    this._disabled = value;

    this._disabled ? this.formControl.disable() : this.formControl.enable();
  }

  public get disabled() {
    return this._disabled;
  }

  @Input() required: boolean = false;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(Injector) private injector: Injector) { }

  ngOnInit(): void {
    this.setFormControl();
    this.required = this.control?.hasValidator(Validators.required);

    this.formControl.valueChanges.subscribe((change) => this.valueChanges.emit(change))
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;

        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: any): void {
    if (this.control) {
      if (this.control.value != value) {
        this.control.setValue(value);
      }
    } else {
      this.control = new FormControl(value);
    }
  }

  ngAfterViewInit(): void { }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}
