<div ngbDropdown container="body" class="show-tick button-action-group dropdown flex-column"
style="z-index: unset !important;" *ngFor="let filter of _filters | keyvalue:asIsOrder ">
<ng-container *ngIf="filter.value.name != ''">
  <!-- Button Label -->

  <fpc-button
    #ddb
    [ngB]="true"
    [buttonLayoutType]="'dropdown-button'"
    [id]="'dropdownMenuButton_'+filter.value.id"
    [buttonClass]="'btn-outline-primary dropdown-toggle'"
    [customNgClass]="[_selectedFiltersMapInital?.has(filter.value.id) && filter.value.values.length!=0 ? 'active' : '', (filter.value.values.length==0 && !searchText || filter.value.badge == '-11') ? 'disabled' : '', filter.value.badge == '-1' ? 'hide-after-arrow' : '']"
    (buttonClick)="resetFilterValues(filter.value.id);"
    [text]="filter.value.label" 
    [filterOption]="true"     
    [disabled]="filter.value.values.length==0 && !searchText || filter.value.badge == '-11'"
  ></fpc-button>

  <!-- X Button Label -->

  <!-- Drop List -->
  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <!-- Tabs -->
    <div *ngIf="showDatePicker" class="tab-menu" [ngClass]="[tabMenuClass]">
      <div class="title search-tab-menu" (click)="tabMenuClass = 'search-active'"
        [ngClass]="[tabMenuClass == 'search-active' ? 'data-active' : '']">
        <h4><strong>Search</strong></h4>
        <!-- <span class="data-active-indicator"></span> -->
      </div>
      <div class="title date-tab-menu" (click)="tabMenuClass = 'date-active'"
        [ngClass]="[tabMenuClass == 'date-active' ? 'data-active' : '']">
        <h4><strong>Date</strong></h4>
        <!-- <span class="data-active-indicator"></span> -->
      </div>
    </div>
    <!-- X Tabs -->

    <!-- Search Tab -->
    <div class="search-tab">
      <div class="header">
        <div class="search">
          <input id="searchSubClientInput_{{filter.value.id}}" type="text" class="form-control" #myInput
            (keyup)="handleInput($event, filter.value.id, myInput.value)" placeholder="{{filter.value.label}}" [(ngModel)]="searchText" />
        </div>
        <div *ngIf="!hideSelectDeselectAll" class="buttons">
          <button type="button" class="btn btn-outline-primary" (click)="onSelectAll($event, filter.value)">
            <span class="title">Select all</span>
          </button>
          <button type="button" class="btn btn-outline-primary" (click)="onDeSelectAll($event, filter.value)">
            <span class="title">Deselect all</span>
          </button>
        </div>
      </div>
      <div class="content" id="filter_list_{{filter.value.id}}">
        <div class="dropdown-item" *ngFor="let value of filter.value.values; let valIndex = index;"
          (click)="onFilterSelected($event, filter.value.id, filter.value.title, filter.value.selectedDate, value)"
          [ngClass]="isFilterSelected(filter.value.id, value) ? 'active' : ''">
          <h4><strong>{{filter.value.name == 'projectFilterOptions' ? (value.projectCode +' '+value.projectName) :
              filter.value.name == 'workSourceFilterOptions' ? value.name :
              value== 'InProgress' ? 'In progress' : value== 'OnHold' ? 'On hold' : value}}</strong></h4>


        </div>
        <div *ngIf="filter.value.values.length == 0" class="dropdown-item" style="pointer-events: none;">
          <h4>No results found</h4>
        </div>
      </div>
      <div class="footer">
        <div class="buttons">
          <button class="apply btn btn-outline-primary" ngbDropdownToggle (click)="applyFilterSelection($event, filter.value.id)"
            [ngClass]="isApplyButtonActive(filter.value.id) ? 'active' : 'disabled'">
            Apply
          </button>
        </div>
      </div>
    </div>
    <!-- X Search Tab -->

  </div>
  <!-- X Drop List -->


</ng-container>
<ng-container *ngIf="filter.value.name == ''">
  <!-- Button Label -->
  <fpc-button
    #ddb
    [buttonLayoutType]="'dropdown-button'"
    [id]="'dropdownMenuButton_'+filter.value.id"
    [buttonClass]="'dropdown-toggle btn btn-outline-primary dropdown-toggle '"
    [customNgClass]="[ _selectedFiltersMapInital?.has(filter.value.id)?'active':'',  filter.value.badge == '-1' ? 'hide-after-arrow' : '']"
    (buttonClick)="resetFilterValues(filter.value.id);"
    [text]="filter.value.label" [filterOption]="true" [ngB]="true"></fpc-button>

  <!-- X Button Label -->

  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
   <fpc-input-date-picker
      [selectedDate]="_selectedFiltersMap.has(filter.value.id) ? _selectedFiltersMap.get(filter.value.id).filterItems : ['', '']"
      [selectedDateDefault]="selectedDateDefault"
      (getFilterSelectionChanged)="onDateSelectionChanged($event, filter.value)"
   ></fpc-input-date-picker>
  </div>
</ng-container>
</div>