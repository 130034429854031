import { Component, Input, OnInit, Injector, forwardRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbCalendar,  NgbDate,  NgbDatepicker,  NgbDatepickerNavigateEvent, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BaseInputComponent } from '../base-input.directive';
@Component({
  selector: 'fpc-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatePickerComponent),
      multi: true,
    },
  ],
})

export class InputDatePickerComponent  extends BaseInputComponent  implements OnInit, AfterViewInit {
  @Input() buttonTitle: string = '';

  @Input() dropDownButtonView: boolean = true;

 @Input()   showDropDown: boolean = false;

  @Input() range: boolean = false; 
  @Input() calendarHeader: boolean = true;
  @Input() cardsRightSidebarWidget: any;
  @Input() closeWhenApply: boolean = false;

  @ViewChild('buttonDrop') buttonDrop: NgbDropdown;
  @ViewChild('dp') dp: NgbDatepicker;

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  hoveredDate: NgbDate | null = null;

  formFrom: UntypedFormGroup;
  formTo: UntypedFormGroup;

  _selectedDate: any;


  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  current: NgbDate;
  currentMonthYear: string = '';
  today = this.calendar.getToday();

  backup: { fromDate: NgbDate; toDate: NgbDate };
  
  events = 
  
  
  [
    {
        "eventId": 21982,
        "class": "Dismissed",
        "left": [
            "12:30",
            "13:30"
        ],
        "center": [
            "Cancelled - Seele Innovations Talk Series ",
            "The Hub"
        ],
        "startDate": "21/06/2023",
        "finishDate": "21/06/2023",
        "eventDays": [
            "21/06/2023"
        ],
        "isBookable": false
    },
    {
        "eventId": 21992,
        "class": null,
        "left": [
            "12:30",
            "13:30"
        ],
        "center": [
            "Seele Innovations Talk Series ",
            "The Hub and online "
        ],
        "startDate": "12/07/2023",
        "finishDate": "12/07/2023",
        "eventDays": [
            "12/07/2023"
        ],
        "isBookable": false
    },
    {
        "eventId": 22140,
        "class": "Dismissed",
        "left": [
            "ALL DAY",
            "EVENT"
        ],
        "center": [
            "Rhino 7.0 - Intermediate",
            "Training Room 1, The Mews"
        ],
        "startDate": "04/07/2023",
        "finishDate": "05/07/2023",
        "eventDays": [
            "04/07/2023",
            "05/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 22145,
        "class": null,
        "left": [
            "ALL DAY",
            "EVENT"
        ],
        "center": [
            "Grasshopper - Intermediate",
            "Training Room 1, The Mews "
        ],
        "startDate": "18/07/2023",
        "finishDate": "19/07/2023",
        "eventDays": [
            "18/07/2023",
            "19/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 22151,
        "class": "Dismissed",
        "left": [
            "ALL DAY",
            "EVENT"
        ],
        "center": [
            "Grasshopper - Beginner",
            "Training Room 1, The Mews "
        ],
        "startDate": "27/06/2023",
        "finishDate": "28/06/2023",
        "eventDays": [
            "27/06/2023",
            "28/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 22164,
        "class": "Dismissed",
        "left": [
            "ALL DAY",
            "EVENT"
        ],
        "center": [
            "Rhino 7.0 - Beginner",
            "Training Room 1, The Mews"
        ],
        "startDate": "06/06/2023",
        "finishDate": "07/06/2023",
        "eventDays": [
            "06/06/2023",
            "07/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 22176,
        "class": "Dismissed",
        "left": [
            "09:00",
            "17:30"
        ],
        "center": [
            "Mandatory Training: Safety by Design",
            "Millennium Chelsea Hotel"
        ],
        "startDate": "08/06/2023",
        "finishDate": "08/06/2023",
        "eventDays": [
            "08/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 22177,
        "class": null,
        "left": [
            "09:00",
            "17:30"
        ],
        "center": [
            "Mandatory Training: Safety by Design",
            "Millennium Chelsea Hotel"
        ],
        "startDate": "18/07/2023",
        "finishDate": "18/07/2023",
        "eventDays": [
            "18/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 22178,
        "class": null,
        "left": [
            "09:00",
            "17:30"
        ],
        "center": [
            "Mandatory Training: Safety by Design",
            "Millennium Chelsea Hotel"
        ],
        "startDate": "27/07/2023",
        "finishDate": "27/07/2023",
        "eventDays": [
            "27/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 23921,
        "class": "Dismissed",
        "left": [
            "18:00",
            "19:00"
        ],
        "center": [
            "Terrarium Workshop, Tuesday 13 June",
            "The Hub"
        ],
        "startDate": "13/06/2023",
        "finishDate": "13/06/2023",
        "eventDays": [
            "13/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 23922,
        "class": null,
        "left": [
            "18:00",
            "19:00"
        ],
        "center": [
            "Terrarium Workshop, Tuesday 18 July",
            "The Hub"
        ],
        "startDate": "18/07/2023",
        "finishDate": "18/07/2023",
        "eventDays": [
            "18/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 25673,
        "class": null,
        "left": [
            "18:00",
            "19:00"
        ],
        "center": [
            "Introduction to Origami Workshop",
            "The Hub"
        ],
        "startDate": "11/07/2023",
        "finishDate": "11/07/2023",
        "eventDays": [
            "11/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 25751,
        "class": "Dismissed",
        "left": [
            "09:30",
            "17:30"
        ],
        "center": [
            "Dynamo Beginner ",
            "Training Room 1, The Mews"
        ],
        "startDate": "14/06/2023",
        "finishDate": "14/06/2023",
        "eventDays": [
            "14/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 25762,
        "class": null,
        "left": [
            "09:30",
            "17:30"
        ],
        "center": [
            "Dynamo Intermediate ",
            "Training Room 1, The Mews"
        ],
        "startDate": "12/07/2023",
        "finishDate": "12/07/2023",
        "eventDays": [
            "12/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 25764,
        "class": null,
        "left": [
            "09:30",
            "17:30"
        ],
        "center": [
            "Python Beginner ",
            "Training Room 1, The Mews"
        ],
        "startDate": "26/07/2023",
        "finishDate": "26/07/2023",
        "eventDays": [
            "26/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 25901,
        "class": "Dismissed",
        "left": [
            "09:30",
            "15:15"
        ],
        "center": [
            "Personal Wellbeing Consultation - Thursday 22 June",
            "Wellbeing room"
        ],
        "startDate": "22/06/2023",
        "finishDate": "22/06/2023",
        "eventDays": [
            "22/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 26062,
        "class": "Dismissed",
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 20 June",
            "Treatment Room"
        ],
        "startDate": "20/06/2023",
        "finishDate": "20/06/2023",
        "eventDays": [
            "20/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 26063,
        "class": "Dismissed",
        "left": [
            "ALL DAY",
            "EVENT"
        ],
        "center": [
            "Massage & reflexology sessions - 27 - 29 June",
            "Treatment Room"
        ],
        "startDate": "27/06/2023",
        "finishDate": "29/06/2023",
        "eventDays": [
            "27/06/2023",
            "28/06/2023",
            "29/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 26070,
        "class": "Dismissed",
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 23 June",
            "Treatment Room"
        ],
        "startDate": "23/06/2023",
        "finishDate": "23/06/2023",
        "eventDays": [
            "23/06/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 26499,
        "class": null,
        "left": [
            "18:00",
            "19:00"
        ],
        "center": [
            "Terrarium Workshop",
            "The Hub"
        ],
        "startDate": "15/08/2023",
        "finishDate": "15/08/2023",
        "eventDays": [
            "15/08/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27045,
        "class": null,
        "left": [
            "09:30",
            "15:30"
        ],
        "center": [
            "Personal Wellbeing Consultation - Thursday 20 July",
            "Wellbeing room"
        ],
        "startDate": "20/07/2023",
        "finishDate": "20/07/2023",
        "eventDays": [
            "20/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27047,
        "class": "Dismissed",
        "left": [
            "09:30",
            "15:30"
        ],
        "center": [
            "One to One Health Screening - Wednesday 5 July ",
            "Wellbeing Room"
        ],
        "startDate": "05/07/2023",
        "finishDate": "05/07/2023",
        "eventDays": [
            "05/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27140,
        "class": "Dismissed",
        "left": [
            "ALL DAY",
            "EVENT"
        ],
        "center": [
            "Massage & reflexology sessions | 3 - 4 July ",
            "Treatment Room"
        ],
        "startDate": "03/07/2023",
        "finishDate": "04/07/2023",
        "eventDays": [
            "03/07/2023",
            "04/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27143,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 10 July ",
            "Treatment Room"
        ],
        "startDate": "10/07/2023",
        "finishDate": "10/07/2023",
        "eventDays": [
            "10/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27144,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 14 July ",
            "Treatment Room"
        ],
        "startDate": "14/07/2023",
        "finishDate": "14/07/2023",
        "eventDays": [
            "14/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27145,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 17 July ",
            "Treatment Room"
        ],
        "startDate": "17/07/2023",
        "finishDate": "17/07/2023",
        "eventDays": [
            "17/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27146,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 21 July ",
            "Treatment Room"
        ],
        "startDate": "21/07/2023",
        "finishDate": "21/07/2023",
        "eventDays": [
            "21/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27147,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 26 July ",
            "Treatment Room"
        ],
        "startDate": "26/07/2023",
        "finishDate": "26/07/2023",
        "eventDays": [
            "26/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27149,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 28 July ",
            "Treatment Room"
        ],
        "startDate": "28/07/2023",
        "finishDate": "28/07/2023",
        "eventDays": [
            "28/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27148,
        "class": null,
        "left": [
            "09:00",
            "16:00"
        ],
        "center": [
            "Massage & reflexology sessions - 31 July ",
            "Treatment Room"
        ],
        "startDate": "31/07/2023",
        "finishDate": "31/07/2023",
        "eventDays": [
            "31/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27165,
        "class": null,
        "left": [
            "12:30",
            "13:15"
        ],
        "center": [
            "Lunchtime Yoga - 11 July",
            "Stars Gym "
        ],
        "startDate": "11/07/2023",
        "finishDate": "11/07/2023",
        "eventDays": [
            "11/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27166,
        "class": null,
        "left": [
            "12:30",
            "13:15"
        ],
        "center": [
            "Lunchtime Yoga - 18 July",
            "Stars Gym "
        ],
        "startDate": "18/07/2023",
        "finishDate": "18/07/2023",
        "eventDays": [
            "18/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27167,
        "class": null,
        "left": [
            "12:30",
            "13:15"
        ],
        "center": [
            "Lunchtime Yoga - 25 July",
            "Stars Gym "
        ],
        "startDate": "25/07/2023",
        "finishDate": "25/07/2023",
        "eventDays": [
            "25/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27169,
        "class": null,
        "left": [
            "12:30",
            "13:30"
        ],
        "center": [
            "Lunchtime Yoga - 13 July",
            "Stars Gym "
        ],
        "startDate": "13/07/2023",
        "finishDate": "13/07/2023",
        "eventDays": [
            "13/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27170,
        "class": null,
        "left": [
            "12:30",
            "13:30"
        ],
        "center": [
            "Lunchtime Yoga - 20 July",
            "Stars Gym "
        ],
        "startDate": "20/07/2023",
        "finishDate": "20/07/2023",
        "eventDays": [
            "20/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27171,
        "class": null,
        "left": [
            "12:30",
            "13:30"
        ],
        "center": [
            "Lunchtime Yoga - 27 July",
            "Stars Gym "
        ],
        "startDate": "27/07/2023",
        "finishDate": "27/07/2023",
        "eventDays": [
            "27/07/2023"
        ],
        "isBookable": true
    },
    {
        "eventId": 27604,
        "class": null,
        "left": [
            "18:00",
            "21:00"
        ],
        "center": [
            "Summer Festival - Friday 21 July ",
            ""
        ],
        "startDate": "21/07/2023",
        "finishDate": "21/07/2023",
        "eventDays": [
            "21/07/2023"
        ],
        "isBookable": false
    },
    {
        "eventId": 27717,
        "class": null,
        "left": [
            "13:30",
            "14:30"
        ],
        "center": [
            "Made In WonderGlass: Cast, Fused and Blown glassmaking in the built environment",
            "MRC and online"
        ],
        "startDate": "11/07/2023",
        "finishDate": "11/07/2023",
        "eventDays": [
            "11/07/2023"
        ],
        "isBookable": false
    }
  ];
   
  @Input() set selectedDateRange(value: any) {
    if (!value || value == null || value == undefined) return;
    if(value){      
        this.fromDate = new NgbDate(
            value.fromDate.year,
            value.fromDate.month,
            value.fromDate.day
        );

        this.toDate = new NgbDate(
            value.toDate.year,
            value.toDate.month,
            value.toDate.day
        );


        this.formFrom = this.fb.group({
            rangeFromDay: [this.fromDate.day],
            rangeFromMonth: [this.fromDate.month],
            rangeFromYear: [this.fromDate.year],
          });
      
          
          this.formTo = this.fb.group({
            rangeToDay: [this.toDate.day],
            rangeToMonth: [this.toDate.month],
            rangeToYear: [this.toDate.year],
          });
    }
  }

  @Input() set selectedDate(value: any) {    
    if (!value || value == null || value == undefined) return;
   
    if(value){ 
   
        this.fromDate = new NgbDate(
            value.year,
            value.month,
            value.day
        );

        this.toDate = null;
    }
  }

  constructor(
    injector: Injector,
    private calendar: NgbCalendar,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    super(injector);

    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {       

   

//    if (this.dropDownButtonView == true) {
//         this.isDropDownButton = true
//     } else {
//         this.isDropDownButton = false;
       

//     }
   
   

    if(this.range) {
        if(this.selectedDateRange){
            this.control.setValue(this.selectedDateRange);
            this.formControl.setValue(this.selectedDateRange);
        }
    

        this.formFrom = this.fb.group({
            rangeFromDay: [this.fromDate.day],
            rangeFromMonth: [this.fromDate.month],
            rangeFromYear: [this.fromDate.year],
          });
      
          
          this.formTo = this.fb.group({
            rangeToDay: [this.toDate.day],
            rangeToMonth: [this.toDate.month],
            rangeToYear: [this.toDate.year],
          });
    } else{
        if(this.selectedDate){
            this.control.setValue(this.selectedDate);
            this.formControl.setValue(this.selectedDate);
        }
        
    }
  }
  checkDropDown(event,dropdown){
    console.log('event',event)
    console.log('drop', dropdown)
    this.buttonDrop.open();
  }

  ngAfterViewInit(): void {
    // if(!this.isDropDownButton){
    //     this.buttonDrop._open = true;
    //     this.buttonDrop.open();
    //     console.log(this.buttonDrop)
    // }
    this.cdRef.detectChanges();
  }
 
  isCurrent(date: NgbDate) {
    return date.equals(this.calendar.getToday());
  }

  isFocused(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  next() {
    this.dp.navigateTo(this.calendar.getNext(this.dp.model.focusDate, 'm'));
  }

  previous() {
    this.dp.navigateTo(this.calendar.getPrev(this.dp.model.focusDate, 'm'));
  }

  onNavigate(event: NgbDatepickerNavigateEvent, dp) {
    if (!this.range) {
      let date: { year: number; month: number };

      date = event.next;

      setTimeout(() => {
        this.currentMonthYear = `${this.months[date.month - 1]} ${date.year}`;
      }, 0);

      this.dp = dp;
    } else {
      if (!this.dp) {
        this.dp = dp;
      }
      setTimeout(() => {
        this.currentMonthYear = `${
          this.months[this.dp.model.focusDate.month - 1]
        } ${this.dp.model.focusDate.year}`;
      }, 0);
    }
  }

  reset() {
    if (this.range) {
      this.fromDate = null;
      this.toDate = null;

      this.formFrom.get('rangeFromDay').setValue(null);
      this.formFrom.get('rangeFromMonth').setValue(null);
      this.formFrom.get('rangeFromYear').setValue(null);

      this.formTo.get('rangeToDay').setValue(null);
      this.formTo.get('rangeToMonth').setValue(null);
      this.formTo.get('rangeToYear').setValue(null);

      this.dp.navigateTo(this.calendar.getToday());
    } else {
      this.fromDate = this.calendar.getToday();
      this.dp.navigateTo(this.calendar.getToday());
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelect(date: NgbDate) {
    if (this.range) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;

            this.formFrom.get('rangeFromDay').setValue(date.day);
            this.formFrom.get('rangeFromMonth').setValue(date.month);
            this.formFrom.get('rangeFromYear').setValue(date.year);
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;

            this.formTo.get('rangeToDay').setValue(date.day);
            this.formTo.get('rangeToMonth').setValue(date.month);
            this.formTo.get('rangeToYear').setValue(date.year);
        } else {
            this.toDate = null;
            this.fromDate = date;

            this.formFrom.get('rangeFromDay').setValue(date.day);
            this.formFrom.get('rangeFromMonth').setValue(date.month);
            this.formFrom.get('rangeFromYear').setValue(date.year);

            this.formTo.get('rangeToDay').setValue(null);
            this.formTo.get('rangeToMonth').setValue(null);
            this.formTo.get('rangeToYear').setValue(null);
        }

    } else {
      this.fromDate = date;
      this.toDate = null;
      
      this.control.setValue(this.fromDate);
      this.formControl.setValue(this.fromDate);
  }

  }

  dayData = (date: NgbDate, current?: { year: number; month: number }): { hasEvents: boolean, isOutside: boolean, previousDayEvent: boolean } => {
    let data = { hasEvents: false, isOutside: false, selectedDate: this.fromDate, previousDayEvent: false };
    
    data.isOutside = current.month !== date.month;
    
    if (this.cardsRightSidebarWidget?.length == 0) return data;


    const dateString = `${date.day < 10 ? '0' + date.day : date.day}/${date.month < 10 ? '0' + date.month : date.month}/${date.year}`;
    
    if(this.cardsRightSidebarWidget?.length > 0){
      this.cardsRightSidebarWidget[0].sections = this.events;

      data.hasEvents = this.cardsRightSidebarWidget[0].sections.filter((section) => section.eventDays.indexOf(dateString) > -1).length > 0 && !data.isOutside;
    }
   

    data.previousDayEvent = this.isPreviousDay(date);

    return data;
  };



  isPreviousDay(date: NgbDate): boolean {
    const dateDate = new Date(`${date.month}/${date.day}/${date.year}`);
    const todayDate = new Date(`${this.today.month}/${this.today.day}/${this.today.year}`);
    return dateDate < todayDate;
  }

  apply() {
        if (!this.fromDate || !this.toDate) return;

        if (this.toFromDifFromBackup()) {
            this.backup = { fromDate: this.fromDate, toDate: this.toDate };
            this.formControl.setValue({
                fromDate: this.fromDate,
                toDate: this.toDate
            });
            this.control.setValue({
                fromDate: this.fromDate,
                toDate: this.toDate
            });
        }
        
        this.cdRef.detectChanges()
  }

  toFromDifFromBackup() {
    if (!this.calendar.isValid(this.fromDate)) return false;
    if (!this.calendar.isValid(this.toDate)) return false;
    if (!this.fromDate.before(this.toDate)) return false;
    if (!this.backup || !this.fromDate || !this.toDate) return true;
    return (
      !this.fromDate.equals(this.backup.fromDate) ||
      !this.toDate.equals(this.backup.toDate)
    );
  }
}