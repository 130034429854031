import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
enum POSITION {
  LEFT = "left",
  RIGHT = "right"
}

@Component({
  selector: 'fpc-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss']
})
export class WidgetContainerComponent {

  init: boolean = true;
  complete: boolean = false;
  _boolean!: boolean;
  isEditActive: boolean = false;

  @Input() model!: any;
  @Input() icon!: any;

  @Input() position: POSITION = POSITION.LEFT;

  @Input() showLoadMoreBtn: boolean = false;
  @Input() set boolean(value: boolean) {
    if (this.init) {
      this._boolean = value;
      this.init = false;
      return;
    }
    if (value === false) {
      this.complete = true;
      setTimeout(() => {
        this._boolean = false;
        this.complete = false;
      }, 3000);
    }
    else this._boolean = true;
  };
  get boolean() {
    return this._boolean;
  }


  _title: string | undefined;
  @Input() showHeader = true;
  @Input() set title(value: string) {
    if (value) {
      this._title = value;
    } else if (this.model) {
      this._title = this.model.title;
    }
  }

  get title() {
    return this._title || (this.model ? this.model.title : '');
  }

  @Input() isCarouselPrevBtnDisabled: boolean = false;
  @Input() isCarouselNextBtnDisabled: boolean = false;
  @Input() isCarouselMinimized: boolean = false;
  @Input() isMapView: boolean = false;
  @Input() showDoubleCardView: boolean = false;
  @Input() showTableView: boolean = false;
  @Input() showCardView: boolean = true;
  @Input() onSidebar: boolean = false;
  @Input() isOneCatSelected: boolean = false;
  @Input() searchSection: any;
  @Input() showSectionExpandBtn: boolean = false;
  @Input() showPreset: boolean = false;
  @Input() showOnlyProjectsWithImagesToggle: boolean = false;
  @Input() showExclamation: boolean = false;
  @Input() mainCommunity: boolean = false;
  @Input() inactive: boolean = false;
  @Input() searchInWidget: boolean = false;
  @Input() externalUrlTitle!: string;

  @Input() search: boolean = false;

  @Output() getWidgetMinimisedChanged = new EventEmitter<any>();
  @Output() backButtonClicked = new EventEmitter<any>();
  @Output() nextButtonClicked = new EventEmitter<any>();
  @Output() editClick = new EventEmitter<boolean>();
  @Output() saveToggle = new EventEmitter<any>();
  @Output() saveToggleMapView = new EventEmitter<any>();
  @Output() preset = new EventEmitter<any>();
  @Output() closeClick = new EventEmitter<any>();
  @Output() searchInWidgetClick = new EventEmitter<any>();

  @Output() showOnlyProjectsWithImages = new EventEmitter<any>();
  @Output() changeToggleState = new EventEmitter<any>();
  @Output() carouselViewChange = new EventEmitter<any>();
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();
  @Output() resetClicked: EventEmitter<any> = new EventEmitter();
  @ContentChild(TemplateRef) templateVariable!: TemplateRef<any>;

  constructor() { }

  onMinimiseBtnClick(isCarouselMinimised: any) {
    this.getWidgetMinimisedChanged.emit(isCarouselMinimised);
  }

  onBackBtnClick(event: any) {
    this.backButtonClicked.emit(event)
  }

  onNextBtnClick(event: any) {
    this.nextButtonClicked.emit(event)
  }

  onEditIconClick(event: any) {
    this.isEditActive = !this.isEditActive
    this.editClick.emit()
  }
  onResetBtnClick(event) {
    this.resetClicked.emit(event)
  }

  onCloseBtnClick() {
    this.closeClick.emit();
  }

  onSearchInWidgetBtnClick() {
    this.searchInWidgetClick.emit();
  }

  toggleViewState(): void {
    if (this.showCardView && !this.showDoubleCardView) {
      this.showTableView = false;
      this.showDoubleCardView = true;
      this.showCardView = false;
    }
    else if (this.showDoubleCardView) {
      this.showTableView = true;
      this.showDoubleCardView = false;
      this.showCardView = false;
    }
    else if (this.showTableView) {
      this.showTableView = false;
      this.showDoubleCardView = false;
      this.showCardView = true;
    }

    this.carouselViewChange.emit({
      showTableView: this.showTableView,
      showDoubleCardView: this.showDoubleCardView,
      showCardView: this.showCardView
    })
  }

  hotJar() {
    window['hj']('event', 'preset-clicked');
  }

  saveToggleState(title: any, value: any) {
    this.saveToggle.emit({ title: title, value: value });
  }
  saveToggleStateMapView(value: any) {
    this.saveToggleMapView.emit(value)
  }
  showOnlyWithImage() {
    this.showOnlyProjectsWithImages.emit(this.searchSection.curRecordsOnPage);
  }
  setPreset() {
    this.hotJar();
    this.showPreset = !this.showPreset;
    this.preset.emit(this.showPreset)
  }
  changeToggle(toogleStasteTittle, toogleStateValue, mapToggleStateValue) {
    this.changeToggleState.emit({
      title: toogleStasteTittle,
      value: toogleStateValue,
      mapToggleStateValue: mapToggleStateValue
    })
  }
  btnClick() {
    this.clickEvent.emit();
  }
}
