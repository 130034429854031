<div ngbAccordion #accordionTemplate="ngbAccordion" [closeOthers]="closeOthers" class=" highlited">
  <div ngbAccordionItem #accordionItem="ngbAccordionItem" *ngFor="let item of accordionPannels" [class]="squared ? 'squared': ''">   

    <div ngbAccordionHeader class="accordion-button custom-header justify-content-between" [ngClass]="[squared ? 'squared ': '', (isAccordionOpened && accordionColorClass) ? accordionColorClass : '']">
      
      <div class="accordion_header_container flex-grow-1 m-0" [ngClass]="item.iconClass ? 'd-flex gap-3':''">
        <i *ngIf="item.iconClass" [class]="item.iconClass"></i>
          
            <div class="d-flex justify-content-between">
              <div class="accordion_title_container d-flex flex-row gap-2 align-items-center">
                <h4 class="accordion_title m-0 fw-bold">{{item.title}}</h4>
                <h4 class="accordion_badge fw-bold m-0">{{item.badge}}</h4>
              </div>
              <div class="d-flex align-items-center">
                <h4 class="accordion_subtitle m-0">{{item.subtitle}}</h4>
              </div>              
            
            </div>
      </div>
      <fpc-button 
        ngbAccordionToggle 
        [buttonLayoutType]="'icon'" 
        [buttonColorClass]="'primary'"
        [iconClass]="!accordionItem.collapsed ? 'fal fa-chevron-up' : 'fal fa-chevron-down'" 
        [iconSize]="iconSize ? iconSize : 'sm'"
        [buttonClass]="!accordionItem.collapsed ? 'active':''"
        (buttonClick)="checkCollapsed(accordionItem)"
        >
      </fpc-button>
    </div>
    
    <div ngbAccordionCollapse>
      <div ngbAccordionBody [class.nested-accordion]="item.panelItems">
        <ng-template>
          <ng-container *ngIf="!item.panelItems; else nestedAccordion">
            <p *ngIf="item.content">{{ item.content }}</p>
            <a *ngIf="item.url" [href]="item.filepath" target="_blank">{{ item.url ? item.url : item.url }}</a>
            <ng-container *ngIf="item.template">
              <ng-container *ngTemplateOutlet="item.template; context: {data: item.templateData }"></ng-container>
            </ng-container>
          </ng-container>

          <ng-template #nestedAccordion>
            <div ngbAccordion [closeOthers]="true" *ngIf="item.panelItems">
              <div ngbAccordionItem #childAccordionItem="ngbAccordionItem" *ngFor=" let child of item.panelItems">
                <div ngbAccordionHeader class="accordion-button custom-header justify-content-between">
                  <div class="d-flex justify-content-between">
                    <div class="accordion_title_container d-flex flex-row gap-2 align-items-center">
                      <h4 class="accordion_title m-0 fw-bold">{{child.title}}</h4>
                      <h4 class="accordion_badge fw-bold m-0">{{child.badge}}</h4>
                    </div>
                    <div class="d-flex align-items-center">
                      <h4 class="accordion_subtitle m-0 ">{{child.subtitle}}</h4>
                    </div>              
                  
                  </div>
                  
                  <fpc-button ngbAccordionToggle [buttonLayoutType]="'icon'" [buttonColorClass]="'primary'"
                    [iconClass]="!childAccordionItem.collapsed ? 'fal fa-chevron-up' : 'fal fa-chevron-down'" [iconSize]="'sm'"
                    [buttonClass]="!childAccordionItem.collapsed ? 'active':''"></fpc-button>
                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <p *ngIf="child.content">{{child.content}}</p>
                      <a *ngIf="child.url" [href]="child.filepath" target="_blank">{{child.url? child.url : child.url }}</a>
                      <ng-container *ngIf="child.template">
                        <ng-container *ngTemplateOutlet="child.template"></ng-container>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>