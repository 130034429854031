<ng-container *ngFor="let item of filter.items; let i = index;">
  <div ngbDropdown class="dropdown" *ngIf="item.badge != '-1' && ((isLineChart && item.name!= 'Favourites') || !isLineChart)">

    <ng-container *ngIf="!isInternalPage">
      <fpc-button *ngIf="(i <= (expandActive ? 999 : 5) || screenWidth < 1024)" [buttonColorClass]="'outline-primary'"
        [buttonClass]="'d-flex align-items-center justify-content-between w-100'" (buttonClick)="onCatClick(item.id, item);" [active]="item.isSelected"
        [text]="isFilterType ? item.name : item.label | categoryPluralTitle | categoryDropdownTitleCase" [badgeText]="item.badge">
      </fpc-button>
    </ng-container>

    <ng-container *ngIf="isInternalPage">

      <div *ngIf="(item.name!='Page info' && item.label!='Page info')" class="d-flex gap-3 align-items-center justify-content-between">

        <fpc-button class="flex-fill" [buttonClass]="'d-flex align-items-center justify-content-between w-100'" [buttonColorClass]="'outline-primary'"
          (buttonClick)="scrollTo(item.name)" [active]="item.isSelected" [title]="getTitle(item)"
          [text]="(isFilterType ? item.name : item.label) | categoryPluralTitle | categoryDropdownTitleCase" [badgeText]="item.badge"></fpc-button>

        <fpc-button [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-primary'" [buttonClass]="'w-100 p-4'" [iconClass]="'fal fa-search'" [iconSize]="'md'"
          [title]="'Search in ' +  (isFilterType ? item.name.toLowerCase() : item.label.toLowerCase()) | categoryPluralTitle | categoryDropdownTitleCase"
          [active]="item.isSelected" (buttonClick)="onCatClick(item.id, item);">
        </fpc-button>

      </div>

      <div *ngIf="(item.name=='Page info' || item.label=='Page info')">

        <fpc-button [buttonColorClass]="'outline-primary'" [buttonClass]="'d-flex align-items-center justify-content-between w-100'"
          [text]="(isFilterType ? item.name : item.label) | categoryPluralTitle | categoryDropdownTitleCase" [active]="item.isSelected"
          (buttonClick)="scrollTo(item.name)" [title]="'Scroll to ' + (isFilterType ? item.name.toLowerCase() : item.label.toLowerCase())"></fpc-button>
      </div>
    </ng-container>

    <div *ngIf="filter.items.length > 6" class="controls col-12 mt-2" [ngClass]="expandActive ? 'expanded' : ''" style="display: block !important;">
      <!-- Desktop Expand Button -->
      <fpc-button [buttonLayoutType]="'icon'" [buttonColorClass]="'outline-primary'" [buttonClass]="'text-center w-100 p-2'" [active]="expandActive"
        [iconClass]="'fal fa-chevron-down'" (buttonClick)="expandActive = !expandActive">
      </fpc-button>
      <!-- X Desktop Expand Button -->
    </div>

  </div>
</ng-container>