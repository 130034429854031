<div class="fpc_steps_container" [ngClass]="{
    show_container_border: showBorder
  }">
  <div>
    <!-- Text -->
    <div class="fpc_steps_text" [ngClass]="{ single_step: totalSteps === 1 }">
      <div *ngFor="let step of steps" [style.color]="step > currentStep ? '' : '#2E9CF0'">
        Step {{ step }}
      </div>
    </div>

    <!-- Circle -->
    <div class="fpc_steps_circle_container" [ngClass]="{
        single_step: totalSteps === 1
      }">
      <div *ngFor="let step of steps" class="circle" [ngClass]="{
          active: step <= currentStep,
          first_step: step === 1,
          last_step: step === totalSteps
        }"></div>
    </div>

    <!-- Line -->
    <div class="circle-connectors">
      <ng-container *ngFor="let step of steps">
        <div *ngIf="step > 1" style="height: 3px" [style.width]="'calc(100% / ' + (totalSteps - 1) + ')'"
          [style.backgroundColor]="step > currentStep ? 'lightgrey' : '#2E9CF0'"></div>
      </ng-container>
    </div>
  </div>
</div>