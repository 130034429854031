<div class="input_container">
  <div class="p-4 border-bottom">
    <fpc-input-text 
      [label]="_label"
      [formControl]="formControl" 
      [placeholder]="searchPlaceholder" 
      [inputSize]="'sm'" 
      [required]="required" 
      class="mb-3"
    ></fpc-input-text>

    <div class="d-flex pt-3 gap-3" *ngIf="areResultsFound">
      <fpc-button 
        [buttonClass]="'w-100'" 
        [buttonColorClass]="'outline-primary'" 
        [text]="'Select all'" 
        (buttonClick)="selectAll()" 
        class="flex-fill"
        [disabled]="allSelected"
      ></fpc-button>

      <fpc-button 
        [buttonClass]="'w-100'"
        [buttonColorClass]="'outline-primary'" 
        [text]="'Deselect all'" 
        (buttonClick)="deSelectAll()" 
        class="flex-fill"
        [disabled]="searchOptionSelected.length == 0">
      </fpc-button>
    </div>
  </div>

  <div class="search_result_container">
    <div class="search_result" *ngFor="let result of filteredList; let i = index;" (click)="optionSelected(i, result)" [ngClass]="{'active': indexArray.includes(i)}">
      <h4 class="mb-0 fw-bold">{{result.description}}</h4>
    </div>

    <div class="search_result pe-none" *ngIf="(!areResultsFound && !warningMessageExist) || (warningMessageExist  && warningMessage?.length > 0)">
      <h4 class="mb-0 fw-bold">{{!areResultsFound && !warningMessageExist ? 'No Results found.': warningMessage}}</h4>
    </div>
  </div>
  
  <div class="p-4 border-top" *ngIf="areResultsFound">
    <fpc-button 
    [buttonClass]="'w-100'" 
    [buttonColorClass]="'outline-primary'" 
    [text]="'Apply'" 
    (buttonClick)="apply()"
    [disabled]="searchOptionSelected.length == 0"></fpc-button>
  </div>

</div>