import {
  Component,
  forwardRef,
  Injector,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { SearchResult } from '../../../shared/_models/search.result.model';
import { BaseInputComponent } from '../base-input.directive';
import { switchMap, map, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'fpc-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSearchComponent),
      multi: true,
    },
  ],
})
export class InputSearchComponent extends BaseInputComponent implements AfterViewInit {

  areMinimumCharactersTyped: boolean = false;
  areResultsFound: boolean = false;
  warningMessageExist: boolean = false;
  newSearchList: Observable<SearchResult[]> = of([]);
  filteredList: SearchResult[] = [];
  active: number = null;
  indexArray: number[] = [];
  searchOptionSelected: any = [];
  allSelected: boolean = false;

  @Input() labelColor: string;
  @Input() isFormValid: boolean = true;
  @Input() lengthSearch: number = 0;  
  @Input() searchPlaceholder: string = '';
  @Input() searchList: SearchResult[] = [];
  @Input() searchList$: Observable<SearchResult[]> = of([]);
  @Input() startWithFullList: boolean = false;
  @Input() warningMessage: string = '';
  @Input() inputSize: string;
  @Input() multiSelect: boolean = false;

  @Output() selectedValues: EventEmitter<any> = new EventEmitter();

  constructor(injector: Injector, private cdRef: ChangeDetectorRef, private formBuilder: UntypedFormBuilder) {
    super(injector);
  }

  ngOnInit(): void {

    if (this.warningMessage?.length) this.warningMessageExist = true;

    this.newSearchList = this.searchList.length > 0 ? of(this.searchList) : this.searchList$;

    if (this.startWithFullList) {     
      this.areResultsFound = true;

      this.newSearchList.pipe(
        map((results: SearchResult[]) => results)
      ).subscribe((res) => {
        this.warningMessageExist = false;
        this.areMinimumCharactersTyped = true;
        this.filteredList = res
      });
    }
  }

  ngAfterViewInit() {    
    
    this.formControl.valueChanges.subscribe((value) => {

      of(value).pipe(
        switchMap((searchString: string) => {
          return this.newSearchList.pipe(          
            map((results: SearchResult[]) => {
              if (searchString?.length > 0) {                
                let filteredRes = results.filter((filter, index) =>                  
                  {
                   if(this.allSelected) this.indexArray.push(filter.id)
                    return filter.description.toLowerCase().includes(searchString?.toLowerCase());
                    }                
                );
                return filteredRes;

              } else {
                this.deSelectAll();
                return this.startWithFullList ? results : []
                         
              }
            }),
            tap((searchRes:SearchResult[]) => {
              if (this.warningMessage?.length) {
                if (searchString.length > this.lengthSearch || this.startWithFullList) {
                  this.warningMessageExist = false;
                  this.areMinimumCharactersTyped = true;
                } else {
                  this.warningMessageExist = true;
                  this.areMinimumCharactersTyped = false
                }
              };
              
              (searchRes.length > 0) ? this.areResultsFound = true : this.areResultsFound = false;
            }),
          );
        })
      ).subscribe((sr: SearchResult[]) => {
        this.filteredList = sr
      });
    });

    this.cdRef.detectChanges();
  }
  
  optionSelected(index: number, result:any) {
    if(!this.searchOptionSelected.includes(result.description)){
      this.searchOptionSelected.push(result.description);
    } else {
      this.searchOptionSelected = this.searchOptionSelected.filter((option)=> option != result.description)
    }
     
      (!this.indexArray.includes(index)) ? 
      this.indexArray.push(index) :
      this.indexArray.splice(index,1);
      
  }

  selectAll(idsFromTyping?:any){
    this.allSelected = true;

    const ids: number[] = this.filteredList.map((element, index) => {
      return index;
    });    
    
    this.indexArray = idsFromTyping ? idsFromTyping : ids; 
    this.searchOptionSelected = this.filteredList;  
  }

  deSelectAll(){
    this.allSelected = false;

    this.indexArray = [];
    this.searchOptionSelected = [];
    this.selectedValues.emit([])
  }

  apply(){
    this.formControl.setErrors(null);
    this.selectedValues.emit(this.searchOptionSelected);
  }
}