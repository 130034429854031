import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderByItem } from '../../../shared/_models/order-by-item.model';

@Component({
  selector: 'fpc-order-by-button',
  templateUrl: './order-by-button.component.html',
  styleUrls: ['./order-by-button.component.scss'],
  host: {
    '[style.width]': 'widthOverride ? widthOverride : null',
  },
})
export class OrderByButtonComponent implements OnInit {
  orderTitle: string = 'Order By';
  orderIcon: string = 'fal fa-light fa-arrow-up-long';

  isSelected: boolean = false;
  _selectedOrder: number[] = [];

  @Input() orderByData;

  @Output() getOrderBySelectionChanged = new EventEmitter<any[]>();

  ngOnInit(): void {
    this.orderByData.forEach((element: OrderByItem) => {
      if (element.isSelected) {

        this._selectedOrder.push(element.id);
        if (element.values && element.selectedValue > -1) {
          this._selectedOrder.push(element.values[element.selectedValue]);
        }
      }
    });
  }

  setClassDirection(item: any, isCatClicked: boolean = false) {
    item.orderDirection =
    item.orderDirection === 'Ascending' ? 'Descending' : 'Ascending';
 
    if(!isCatClicked) this.orderBySelectionChanged(item);
  }

  onCategoryClick(item: OrderByItem, val?: any): void {
    if (this._selectedOrder.length > 0)  this._selectedOrder = [];
    
    item.isSelected = true;
    
    this.orderByData.forEach((element: OrderByItem) => {        

      element.isSelected = (element.id == item.id) ? true : false;     
      
    });
    
    this.setClassDirection(item, true);
    this._selectedOrder.push(item.id);
    
    if (item.name == 'Reaction') {
      item.selectedValue == val;
      this._selectedOrder.push(val);
    }
    
    this.orderBySelectionChanged(item);
  }

  orderBySelectionChanged(item:any) {    
      item.orderIcon =
        item.orderDirection == 'Ascending'
          ? 'fal fa-light fa-arrow-up-long'
          : 'fal fa-light fa-arrow-down-long';
    
    this.getOrderBySelectionChanged.emit(this._selectedOrder);
  }

}
