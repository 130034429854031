<!-- Content -->
<div>
  <!-- List -->
  <ul style="list-style-type: none; background-color: white;" [class]="borderClass ? borderClass : ''">
    <li
      *ngFor="let link of model; let first = first"
      [class]="(borderClass ? borderClass : '') + ' p-2 ' + (linkPosition ? linkPosition : '') + ' ' + (linkDecoration? linkDecoration : '')+ ' ' + (linkClass? linkClass : '')" [ngClass]="{ 'border_top_dashed': first }"
    >
      <a
        *ngIf="CheckIsExternalLink(link.url)"
        [href]="link.url"
        target="_blank"
      >
        <p [class]="(linkSize ? linkSize : '') + ' turquoise m-0'">{{ link.content }}</p>
      </a>

      <a *ngIf="!CheckIsExternalLink(link.url)" [routerLink]="link.url">       
        <p [class]="(linkSize ? linkSize : '') + ' turquoise m-0'">{{ link.content }}</p>
      </a>
    </li>
  </ul>
  <!-- X List -->
</div>
<!-- X Content -->
