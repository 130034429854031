import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToggleComponent } from './toggle.component';

@NgModule({
  declarations: [
    ToggleComponent
  ],
  imports: [
    CommonModule,
    NgbModule
  ], 
  exports: [
    ToggleComponent
  ]
})
export class ToggleModule { }
