import {
  Directive,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseFormComponent<M> implements OnInit {

  protected _model!: M;

  private _idModel!: string;

  protected onDestroy$ = new Subject();

  protected formBuilder: UntypedFormBuilder;

  @Input() form: UntypedFormGroup;

  protected get idModel(): any {
    return this._idModel;
  }

  @Input() protected set idModel(value: any) {
    if (value) {
      this._idModel = value;
      this.loadAPIModel(value);
    }
  }

  private set param(key: string) {
    if (key && !this._idModel) {
      this.activatedRoute.paramMap
        //@ts-ignore
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((param: any) => {
          const id = param.params[key];
          if (id) {
            this.idModel = isNaN(id) ? id : +id;
          }
        });
    }
  }

  @Input() set model(value: M) {
    if (!value) return;
    this._model = value;
    if (this._model) {
      this.handleModel();
    } else {
      console.log('no model');
    }
    if (this.form && this.model) {
      this.patchForm(this._model, this.form);
    }
  }

  get model(): M {
    return this._model;
  }

  @Output() modelLoaded: EventEmitter<M> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (!this.idModel) {
      this.param = this.getParam();
    }

    this.form = this.newForm(
      this.form ? this.form : this.formBuilder.group({})
    );

    if (this.form && this.model) {
      this.patchForm(this.model, this.form);
    }
  }
  protected checkFormValidity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return false;
    } else {
      return true;
    }
  }

  onSubmit(callBackFunction?: any): void {
    this.checkFormValidity();

    const model = this.transformDataToSubmit(this.form);

    const formAction: Observable<M> = this.isNewEntry() ? this.save(model) : this.update({ ...model, id: this.idModel });

    formAction.pipe(takeUntil(this.onDestroy$)).subscribe(
      (APImodel) => {
        this.submitComplete(APImodel);
        if (callBackFunction) callBackFunction.onComplete();
      }
    );
  }

  public isNewEntry() {
    return this.model === undefined && this.idModel === undefined;
  }

  loadAPIModel(id: any) {
    this.loadModel(id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((model: M) => {
        this._model = model;
        this.modelLoaded.emit(model);
      });
  }

  protected abstract getParam(): string;

  protected abstract loadModel(id: any): Observable<M>;

  protected handleModel(): void { }

  abstract newForm(form?: UntypedFormGroup): UntypedFormGroup;

  abstract patchForm(model: M, form: UntypedFormGroup): void;

  abstract transformDataToSubmit(form?: UntypedFormGroup): M;

  abstract save(model: M): Observable<M>;

  abstract update(model: M): Observable<M>;

  abstract submitComplete(model: M): void;


  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }
}