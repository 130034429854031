import { Component, Input, OnInit } from '@angular/core';
import { LinkListModel } from '../../shared/_models/link-list.model';

@Component({
  selector: 'fpc-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent implements OnInit {
  @Input() public model!: LinkListModel;
  @Input() borderClass: string = 'dashed';
  @Input() linkDecoration: string = 'default';
  @Input() linkPosition: string = 'left';
  @Input() linkSize: string = 'lg';

  constructor() {}

  isExternalLink = false;

  ngOnInit(): void {}

  CheckIsExternalLink(link: string): boolean {
    if (
      link &&
      (link.includes('http') ||
        link.includes('www') ||
        link.includes('mailto') ||
        link.includes('#'))
    ) {
      return true;
    } else {
      return false;
    }
  }
}
