<div class=" mb-4">
  <h1 class="mt-0" [ngClass]="[selector ? 'mb-5' : 'mb-3']">
    <strong>{{title}}</strong><span *ngIf="selector" style="color: #2e9cf0"> '{{selector}}'</span>
  </h1>
</div>

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" style="border-bottom: none;">

  <li *ngFor="let item of listNavItem" [ngbNavItem]="item.fragment">
    <a ngbNavLink>{{item.title }}</a>
    <ng-template ngbNavContent>
      <div *ngIf="item.fragment == '1' ">
        <ng-content select="[first]"></ng-content>
      </div>
      <div *ngIf="item.fragment == '2' ">
        <ng-content select="[second]"></ng-content>
      </div>
      <div *ngIf="item.fragment == '3' ">
        <ng-content select="[third]"></ng-content>
      </div>
    </ng-template>
  </li>

</ul>
<div [ngbNavOutlet]="nav" class="outlet" [ngClass]="[ nav.activeId == '1' && nav.links?.first.elRef.nativeElement.text == 'Preview' ? 'preview' : '' ]">
</div>