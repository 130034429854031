import { Component, forwardRef, Input, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true
    }
  ]
})
export class InputTextareaComponent extends BaseInputComponent {
  @Input() minlength!: string;
  @Input() maxlength: number = 300;
  @Input() labelColor!: string;
  @Input() iconSize: string = 'md'

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInitForChildren(): void { }

}
