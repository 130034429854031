import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fpc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @ViewChild(NgbDropdown)  public buttonDrop: NgbDropdown;
  @Input() id: string;
  @Input() buttonColorClass: string = 'outline-primary';
  @Input() buttonClass: string;
  @Input() buttonText: string;
  @Input() buttonActive: boolean = false;
  @Input() iconLayout: boolean = false;
  @Input() buttonMenuPlacement: string | string[];
  @Input() iconSize: string;
  @Input() iconClass: string;
  @Input() disabled: boolean;
  @Input() customNgClass: any;

  @Output() buttonClick = new EventEmitter<any>();
  @Output() isOpened = new EventEmitter<any>();
  @Input() filterOption: boolean = false;
  @Input() menuClass: string='';
  @Input() autoclose: boolean = true;
  @Input() closeOnApply: boolean = false;

  

  onButtonClick(e) {
    this.buttonClick.emit(e)
  }

  isDropdownOpen(e) {
    this.isOpened.emit(e);
  }

}
